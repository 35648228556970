import React, { useState } from "react";
import styled from "@emotion/styled";
import PropTypes, { string } from "prop-types";
import { db } from "../firebase";
import { doc, deleteDoc, updateDoc } from "firebase/firestore";
import { resetButton } from "../styles/styles";
import {
  colorDarkBlue,
  colorDarkTurquoise,
  colorOchre,
  colorTurquoise,
  fontBoldBody,
} from "../styles/_variables";

const TodoItem = ({ todoArr, oldInput }) => {
  const todoDb = doc(db, "todos", todoArr.id);
  const [newInput, setNewInput] = useState(oldInput);
  const [inEditMode, setInEditMode] = useState(false);

  // Sette done / ikke done - på CHECK-boksen
  const toggleTodo = () => {
    updateDoc(todoDb, {
      isNew: !todoArr.item.isNew,
    }).then(() => {
      return true;
    });
  };

  // Sletter den.. - SLETT-knappen
  const deleteTodo = () => {
    deleteDoc(todoDb).then(() => {
      return true;
    });
  };

  // Toggler edit mode - ENDRE / AVBRYT
  const toggleEditMode = () => {
    let hepp = newInput;
    if (inEditMode) {
      setInEditMode(false);
      hepp = todoArr.item.todo;
    } else {
      setInEditMode(true);
    }
    return hepp;
  };

  // Onchange på input
  const editTodo = (e) => {
    const newText = e.target.value;
    setNewInput(newText);
  };

  // Lagrer til Firebase - LAGRE-knappen
  const saveChanges = () => {
    updateDoc(todoDb, {
      todo: newInput,
    }).then(() => {
      return true;
    });
    setInEditMode(false);
  };

  return (
    <ListItem>
      {inEditMode ? (
        <EditInput
          type={"text"}
          value={newInput}
          onChange={(e) => editTodo(e)}
        />
      ) : (
        <>
          <Icon
            className={`${todoArr.item.isNew ? "new" : "done"}`}
            onClick={() => toggleTodo()}
          />
          <Text>{todoArr.item.todo}</Text>
        </>
      )}
      <Actions>
        {inEditMode && (
          <button
            onClick={() => saveChanges()}
            className={"save-updated-todo"}
            type={"submit"}
          >
            Lagre
          </button>
        )}

        <button onClick={() => toggleEditMode()} className={"toggle-edit-mode"}>
          {inEditMode ? "Avbryt" : "Endre"}
        </button>
        <button onClick={() => deleteTodo()} className={"delete-todo"}>
          Slett
        </button>
      </Actions>
    </ListItem>
  );
};

const ListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  margin-bottom: 0.75rem;
  padding-bottom: 0.75rem;
  list-style: none;
  border-bottom: 1px solid ${colorOchre};
  transition: all 1s ease;
`;

const Text = styled.span`
  ${fontBoldBody};
`;

const EditInput = styled.input`
  ${fontBoldBody};
  padding: 0.5rem;
  border: none;
  background-color: #fff;
  width: 100%;
  font-size: 1rem;
`;

const Actions = styled.div`
  display: flex;
  margin-left: auto;
  gap: 1rem;
  button {
    ${resetButton};
    width: 80px;
    &.delete-todo {
      &:hover {
        background-color: ${colorOchre};
        color: ${colorDarkBlue};
        font-weight: bold;
      }
    }
    &.toggle-edit-mode {
      background-color: ${colorDarkTurquoise};
      color: ${colorDarkBlue};
      font-weight: bold;
      &:hover {
        background-color: ${colorTurquoise};
      }
    }
  }
`;

const Icon = styled.button`
  ${resetButton};
  display: inline-block;
  margin-right: 0.5rem;
  padding: 0;
  width: 2rem;
  height: 2rem;
  border: 2px solid ${colorDarkTurquoise};
  background-color: transparent;
  transition: all 200ms ease;

  &.done {
    background-color: ${colorDarkTurquoise};
    border-color: transparent;
  }
`;

export default TodoItem;

TodoItem.propTypes = {
  todoArr: PropTypes.object,
  oldInput: string,
};
