import React from "react";
import styled from "@emotion/styled";
import {
  backgroundGradient,
  colorDarkTurquoise,
  colorOchre,
  colorTurquoise,
  colorYellow,
} from "../styles/_variables";
import { Link } from "react-router-dom";

function Header() {
  return (
    <Wrapper>
      <Link to={"/"} className={"home-link"}>
        <h1>
          Jaja, Kaja<span>– du som står der og svaia</span>
        </h1>
      </Link>
      {/*<ul>*/}
      {/*  <li>*/}
      {/*    <Link to={"/"}>Hjem</Link>*/}
      {/*  </li>*/}
      {/*  <li>*/}
      {/*    <Link to={"/kaja/get-shit-done"}>Get shit done</Link>*/}
      {/*  </li>*/}
      {/*</ul>*/}
    </Wrapper>
  );
}

const Wrapper = styled.header`
  padding: 2rem;
  margin-bottom: 3rem;
  text-align: center;
  background: ${backgroundGradient};
  color: ${colorTurquoise};
  .home-link {
    display: inline-block;
    text-decoration: none;
    &:focus {
      outline: 2px solid ${colorDarkTurquoise};
    }
    &:hover {
      h1 {
        color: ${colorTurquoise};
      }
    }
    h1 {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: ${colorYellow};
      transition: color 150ms ease;
      span {
        display: inline-block;
        margin-top: 3px;
        font-size: 1.5rem;
        color: ${colorOchre};
      }
    }
  }

  ul {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
    justify-content: center;
    list-style: none;
    li {
      a {
        color: ${colorYellow};
        &:focus {
          outline: 2px solid ${colorDarkTurquoise};
        }
      }
    }
  }
`;

export default Header;
