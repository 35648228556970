import React from "react";
import styled from "@emotion/styled";
import { colorLightYellow } from "../styles/_variables";
import { centerContent } from "../styles/styles";
import { Link } from "react-router-dom";

function IkkeFunnetPage() {
  return (
    <Wrapper>
      <h2>404!</h2>
      <p>
        Ai ai, her var det ingenting. Gå <Link to={"/"}>hjem igjen.</Link>
      </p>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  ${centerContent};
  ${centerContent};
  padding: 1rem;
  background-color: ${colorLightYellow};
`;

export default IkkeFunnetPage;
