import { css } from "@emotion/react";
import { fontBody, colorDarkBlue } from "./_variables";

export const resetButton = css`
  padding: 0.5rem 1rem;
  ${fontBody};
  -webkit-appearance: none;
  border: none;
  background-color: ${colorDarkBlue};
  color: #fff;
  text-transform: uppercase;
`;

export const centerContent = css`
  margin: 0 auto;
  padding: 0 1rem;
  max-width: 620px;
  width: 95%;
`;
