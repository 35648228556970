import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { db } from "../firebase";
import {
  collection,
  onSnapshot,
  serverTimestamp,
  addDoc,
  query,
  orderBy,
} from "firebase/firestore";

import {
  backgroundGradient,
  colorDarkBlue,
  colorLightYellow,
  colorOchre,
  colorTurquoise,
  colorYellow,
  fontBody,
} from "../styles/_variables";
import TodoItem from "./TodoItem";
import { resetButton } from "../styles/styles";

function TodoList() {
  const todoDb = collection(db, "todos");

  const q = query(
    todoDb,
    orderBy("isNew", "desc"),
    orderBy("timestamp", "desc")
  );

  const [todos, setTodos] = useState([]);
  const [input, setInput] = useState("");

  useEffect(() => {
    onSnapshot(q, (snapshot) => {
      setTodos(
        snapshot.docs.map((doc) => ({
          id: doc.id,
          item: doc.data(),
        }))
      );
    });
  }, [input]);

  const addTodo = (e) => {
    e.preventDefault();
    addDoc(collection(db, "todos"), {
      todo: input,
      timestamp: serverTimestamp(),
      isNew: true,
      status: "new",
    }).then(() => {
      setInput("");
    });
  };

  return (
    <Wrapper>
      {/*<h2>Legg til ny task</h2>*/}
      <Form action="">
        <div>
          <Input>
            <label htmlFor="newTaskInput">Ny oppgave</label>
            <input
              type="text"
              autoFocus
              name={"newTaskInput"}
              id={"newTaskInput"}
              placeholder={"Ny oppgave"}
              value={input}
              onChange={(e) => setInput(e.target.value)}
            />
          </Input>
          <Button onClick={addTodo} type={"submit"} disabled={input.length < 5}>
            Legg til
          </Button>
        </div>
      </Form>
      <List>
        {todos.length === 0 ? (
          <p>Ingen oppgaver! Good job! Lag noen nye 🔥</p>
        ) : (
          todos.map((obj, index) => {
            return (
              <TodoItem
                todoArr={obj}
                key={`min-todo-${index}`}
                oldInput={obj.item.todo}
              />
            );
          })
        )}
      </List>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: 1rem auto;
  max-width: 700px;
  width: 90%;

  h2 {
    padding: 2rem;
    background: ${backgroundGradient};
    color: ${colorTurquoise};
  }
`;

const List = styled.div`
  padding: 2rem;
  background-color: ${colorLightYellow};
  > p {
    margin-bottom: 0;
    text-align: center;
  }
`;

const Form = styled.form`
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding: 3rem 2rem 4rem;
    width: 100%;
    background-color: ${colorYellow};
  }
`;

const Button = styled.button`
  ${resetButton};
  margin-left: 0.5rem;
  &:disabled {
    background-color: lightgray;
  }
  &:hover:not(&:disabled) {
    background-color: ${colorOchre};
    color: ${colorDarkBlue};
    font-weight: bold;
  }
`;

const Input = styled.div`
  justify-self: stretch;
  flex-grow: 1;
  width: 70%;
  label {
    display: none;
  }
  input {
    display: block;
    width: 100%;
    padding: 0.5rem 1rem;
    border-radius: 0;
    border: none;
    text-align: center;
    ${fontBody};
    font-size: 1rem;
    background-color: transparent;
    border-bottom: 3px solid ${colorOchre};
    &:focus {
      outline: none;
      border-bottom-color: ${colorTurquoise};
    }
  }
`;

export default TodoList;
