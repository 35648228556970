import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAx0kI0xXp5FCLEBdfa7A9XwYe40eG88Jc",
  authDomain: "getshitdone-65a0f.firebaseapp.com",
  projectId: "getshitdone-65a0f",
  storageBucket: "getshitdone-65a0f.appspot.com",
  messagingSenderId: "231654805409",
  appId: "1:231654805409:web:93d3c362e871578a6845e0",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
export { db };
