import { css } from "@emotion/react";
import Bisky from "../assets/images/momohead.jpg";
import {
  colorDarkBlue,
  colorDarkTurquoise,
  colorTurquoise,
  fontBoldBody,
  fontHeading,
} from "./_variables";

export const commonCss = css`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  html {
    font-size: 16px;
  }
  body {
    min-height: 100vh;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 1rem;
    background-image: linear-gradient(black, black), url(${Bisky});
    background-size: cover;
    background-blend-mode: saturation;
    background-repeat: no-repeat;
    background-position: center center;
  }

  h1,
  h2 {
    ${fontHeading};
  }
  h1 {
    font-size: 3rem;
  }
  h2 {
    margin-bottom: 1rem;
  }
  p {
    margin-bottom: 1rem;
    max-width: 600px;
    line-height: 1.5rem;
    a {
      ${fontBoldBody};
      text-transform: unset;
      padding: 0 0.25rem;
      color: ${colorDarkBlue};
      transition: background-color 150ms ease;
      &:hover {
        background-color: ${colorTurquoise};
      }
      &:focus {
        outline: 2px solid ${colorDarkTurquoise};
      }
    }
  }
`;
