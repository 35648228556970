import React from "react";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import { centerContent } from "../styles/styles";
import {
  colorLightYellow,
  colorTurquoise,
  colorYellow,
} from "../styles/_variables";
import KajaFleurImage from "../assets/images/kajafleur.jpg";

function HjemPage() {
  return (
    <>
      <Wrapper>
        <h2>Hei der! 🦄</h2>
        <p>
          Jeg er Kaja. Skulle gjerne ha vist deg en skikkelig kul side her, men
          skomakerens barn osv. Den er i hvert fall mer oppdatert enn det som lå
          her sist, si.
        </p>
        <p>
          Jobbdagene mine tilbringes hos{" "}
          <a href="https://eggsdesign.com/" target={"_blank"} rel="noreferrer">
            Eggs Design i Oslo
          </a>
          , hvor jeg er frontend-utvikler. Jeg har vært så heldig å jobbe med
          kode siden 2011, og digger det. Hvis du vil jobbe med meget dyktige og
          fine folk,{" "}
          <a href={"https://eggsdesign.com/join-us"}>
            {" "}
            send oss en søknad da vel!
          </a>
        </p>
        <p>
          Ellers blir det mye hund, musikk, gaming, strikk, venner og kos. Og
          surøl når jeg kan 🍺
        </p>
      </Wrapper>
      <Figure>
        <figcaption>💙</figcaption>
        <img src={KajaFleurImage} alt={"Kaja og Fleur er bestevenner"} />
      </Figure>
    </>
  );
}

const Wrapper = styled.div`
  ${centerContent};
  padding: 1rem;
  background-color: ${colorLightYellow};
`;

const spin = keyframes`
  from {
    transform: rotate(0deg) translate(150px);
  }
  to {
    transform: rotate(360deg) translate(150px);
  }
`;

const Figure = styled.figure`
  position: relative;
  margin: 2rem auto;
  padding: 1rem;
  width: 300px;
  figcaption {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-origin: 150px 150px;
    color: ${colorYellow};
    animation: ${spin} 4s linear infinite;
    border-radius: 50%;
  }
  img {
    display: block;
    width: 100%;
    border-radius: 50%;
    border: 10px solid ${colorTurquoise};
  }
`;

export default HjemPage;
