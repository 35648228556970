import { css } from "@emotion/react";

export const fontHeading = css`
  font-family: "Playfair Display", serif;
  font-weight: 900;
`;

export const fontBody = css`
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1rem;
  font-weight: 400;
`;

export const fontBoldBody = css`
  font-weight: 600;
  font-family: "Source Sans Pro", sans-serif;
  text-transform: uppercase;
`;

export const colorYellow = "#fbdd64";
export const colorLightYellow = "#ffe992";
export const colorBlack = "#131313";
export const colorTurquoise = "#aeccd1";
export const colorDarkTurquoise = "#48abba";
export const colorOchre = "#c2b67e";
export const colorDarkBlue = "#202A36";
export const backgroundGradient =
  "linear-gradient(to right, rgba(24, 38, 52, 0.75) 0%, rgba(24, 38, 52, 0.8) 42%, rgba(24, 38, 52, 0.91) 79%, rgba(24, 38, 52, 0.93) 100%)";

export const momoFace = "#e39c67";
export const momoFaceDark = "#cc824b";
export const momoEar = "#ff9279";
export const momoStroke = "#b25317";
export const momoWhite = "#ffe6d3";
export const momoWhiteDark = "#f1cbae";
export const momoEye = "#c55f1f";
