import { Global } from "@emotion/react";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Header from "./components/Header";
import HjemPage from "./pages/hjem";
import GetShitDonePage from "./pages/kaja/get-shit-done";
import IkkeFunnetPage from "./pages/ikke-funnet";

import { commonCss } from "./styles/global";

function App() {
  return (
    <Router>
      <Global styles={commonCss} />
      <Header />

      <Routes>
        <Route path={"*"} element={<IkkeFunnetPage />} />
        <Route exact path={"/"} element={<HjemPage />} />
        <Route path={"/kaja/get-shit-done"} element={<GetShitDonePage />} />
      </Routes>
    </Router>
  );
}
export default App;
