import React from "react";
import styled from "@emotion/styled";
import TodoList from "../../components/TodoList";

function GetShitDonePage() {
  return (
    <Wrapper>
      <TodoList />
    </Wrapper>
  );
}

const Wrapper = styled.div``;

export default GetShitDonePage;
